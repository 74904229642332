<template>
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{ $t('common.contact') }}</span>
			</div>
			
			<div class="content">
				<div class="content-container">
					
					<div class="contact_box">
						<div class="list">
							<!-- :class="{selected: Telegram}"  -->
							<div class="item" @click="handleSelect(0)">
								<div class="text"><i class="iconfont icon-telegram"></i>Telegram</div>
							</div>
							<!-- :class="{selected: Facebook}"  -->
							<div class="item" @click="handleSelect(1)">
								<div class="text"><i class="iconfont icon-facebook"></i>Facebook</div>
							</div>
							<!-- :class="{selected: Twitter}"  -->
							<div class="item" @click="handleSelect(2)">
								<div class="text"><i class="iconfont icon-twitter"></i>Twitter</div>
							</div>
							<!-- :class="{selected: Discord}"  -->
							<div class="item" @click="handleSelect(3)">
								<div class="text"><i class="iconfont icon-discord"></i>Discord</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<div class="content-container">
					
					<div class="contact_box">
						<div class="list">
							<!--  :class="{selected: Telegram}" -->
							<div class="item" @click="handleSelect(0)">
								<div class="text"><i class="iconfont icon-telegram"></i>Telegram</div>
							</div>
							<!--  :class="{selected: Facebook}" -->
							<div class="item" @click="handleSelect(1)">
								<div class="text"><i class="iconfont icon-facebook"></i>Facebook</div>
							</div>
							<!--  :class="{selected: Twitter}" -->
							<div class="item" @click="handleSelect(2)">
								<div class="text"><i class="iconfont icon-twitter"></i>Twitter</div>
							</div>
							<!--  :class="{selected: Discord}" -->
							<div class="item" @click="handleSelect(3)">
								<div class="text"><i class="iconfont icon-discord"></i>Discord</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</template>
		
        <!-- 联系我们弹窗 -->
		<el-dialog :title="$t('common.contact')" center :visible.sync="showContact" :show-close="false">
			<ul class="contaceList">
                <li v-for="(item,index) in contactList" :key="index" @click="jumpOut(item.wechat)">
                    <i v-if="sourceImg==0" class="iconfont icon-telegram"></i>
                    <i v-else-if="sourceImg==1" class="iconfont icon-facebook"></i>
                    <i v-else-if="sourceImg==2" class="iconfont icon-twitter"></i>
                    <i v-else-if="sourceImg==3" class="iconfont icon-discord"></i>
                    <span>{{item.nickname}}</span>
                </li>
            </ul>
		</el-dialog>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'contact.styl';
</style>

<script>
	import { Loading } from 'element-ui';
	const loading = Loading.service({
				target: '.main',
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'apploading',
			});
	import { mapGetters } from 'vuex';
	// api
	import {getService} from '@/api/contact'
	export default ({
		name: 'contact',
		data() {
			return {
				// Telegram: false,
				// Facebook: false,
				// Twitter: false,
				// Discord: false,
				// 联系我们
				showContact:false,
				contactList:[],
				sourceImg:0,//联系方式icon 0-电报 1-facebook 2-twitter 3-discord
			}
		},
		mounted() {
			loading.close();
		},
		computed:{
			...mapGetters(['account','isMobile']),
		},
		methods: {
			handleSelect(data) {
            	this.sourceImg=data
				var source;//来源
				switch(data) {
					case 0:
						// this.Telegram = !this.Telegram;
						source=1;
						break;
					case 1:
						// this.Facebook = !this.Facebook;
						source=2;
						break;
					case 2:
						// this.Twitter = !this.Twitter;
						// source=0;//
						source=4;//
						break;
					case 3:
						// this.Discord = !this.Discord;
						source=3;
						break;
					default:
						break;
				}
				getService({
					account:this.account,
					source,//0-微信，1-telegram，2-Facebook，3-discord
				}).then(res=>{
					// console.log(res)
					if(!!res){
						if(res.length==1){
							// 如果只有一个联系方式 直接打开
							window.location.href=res[0].wechat
						}else if(res.length>1){
							// 如果有多个联系方式 打开弹窗展示列表
							this.contactList=res
							this.showContact=true
						}else{
							// 没有联系方式
						}
					}
				})
			},
			// 跳外部链接
			jumpOut(path){
				window.location.href=path
			},
		}
	})
</script>
